import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";

// Externals
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  // ListSubheader,
  Typography,
  withStyles,
} from "@material-ui/core";

// Material icons
import {
  DashboardOutlined as DashboardIcon,
  AssignmentOutlined as AssignmentIcon,
  SettingsInputSvideoOutlined as CategoryIcon,
  LocalShippingOutlined as LocalShippingIcon,
  // PeopleOutlined as PeopleIcon,
  ShoppingBasketOutlined as ShoppingBasketIcon,
  LocationOnOutlined as LocationOnIcon,
  SupervisedUserCircleOutlined as SupervisedUserCircleIcon,
  Event as EventIcon,
  // LockOpenOutlined as LockOpenIcon,
  // TextFields as TextFieldsIcon,
  // ImageOutlined as ImageIcon,
  InfoOutlined as InfoIcon,
  // AccountBoxOutlined as AccountBoxIcon,
  SettingsOutlined as SettingsIcon,
} from "@material-ui/icons";
import { logOut } from "redux/actions/index";

// Component styles
import styles from "./styles";
import { getResizedImage } from "services/utility";

class Sidebar extends Component {
  logout = () => {
    const { dispatch } = this.props;
    dispatch(logOut());
  };

  render() {
    const { classes, className, user } = this.props;
    const { userInfo } = user;
    const { shop } = userInfo;
    const rootClassName = classNames(classes.root, className);
    const isPolice = shop && shop.special_access;
    // const isPolice = true
    return (
      <nav className={rootClassName}>
        <div className={classes.logoWrapper}>
          <Link className={classes.logoLink} to="/">
            <img
              alt="Shop logo"
              className={classes.logoImage}
              src="/public/images/logos/shopsapp.jpeg"
            />
          </Link>
        </div>
        <Divider className={classes.logoDivider} />
        <div className={classes.profile}>
          <Link to="/">
            <Avatar
              alt={shop ? shop.name : "title"}
              className={classes.avatar}
              src={
                shop && shop.image_url
                  ? getResizedImage(shop.image_url, 200, 200)
                  : "/images/avatar.png"
              }
            />
          </Link>
          <Typography className={classes.nameText} variant="h6">
            {shop ? shop.name : ""}
          </Typography>
          <Typography className={classes.bioText} variant="caption">
            {shop ? shop.category : ""}
          </Typography>
        </div>
        <Divider className={classes.profileDivider} />
        <List component="div" disablePadding>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to="/dashboard"
          >
            <ListItemIcon className={classes.listItemIcon}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Dashboard"
            />
          </ListItem>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to="/products"
          >
            <ListItemIcon className={classes.listItemIcon}>
              <ShoppingBasketIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Products"
            />
          </ListItem>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to="/orders"
          >
            <ListItemIcon className={classes.listItemIcon}>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Orders"
            />
          </ListItem>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to="/categories"
          >
            <ListItemIcon className={classes.listItemIcon}>
              <CategoryIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Categories"
            />
          </ListItem>

          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to="/delivery-partner"
          >
            <ListItemIcon className={classes.listItemIcon}>
              <LocalShippingIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Delivery Partners"
            />
          </ListItem>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to="/order-locations"
          >
            <ListItemIcon className={classes.listItemIcon}>
              <LocationOnIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Active Order Locations"
            />
          </ListItem>

          {isPolice ? (
            <ListItem
              activeClassName={classes.activeListItem}
              className={classes.listItem}
              component={NavLink}
              to="/police"
            >
              <ListItemIcon className={classes.listItemIcon}>
                <SupervisedUserCircleIcon />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.listItemText }}
                primary="Police"
              />
            </ListItem>
          ) : null}
          {isPolice ? (
            <ListItem
              activeClassName={classes.activeListItem}
              className={classes.listItem}
              component={NavLink}
              to="/reservations"
            >
              <ListItemIcon className={classes.listItemIcon}>
                <EventIcon />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.listItemText }}
                primary="Reservations"
              />
            </ListItem>
          ) : null}
        </List>
        <Divider className={classes.listDivider} />
        <List
          component="div"
          disablePadding
          // subheader={
          //   <ListSubheader className={classes.listSubheader}>
          //     Account
          //   </ListSubheader>
          // }
        >
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to="/settings"
          >
            <ListItemIcon className={classes.listItemIcon}>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Settings"
            />
          </ListItem>
          <ListItem
            className={classes.listItem}
            component="div"
            onClick={this.logout}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <InfoIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Logout"
            />
          </ListItem>
        </List>
      </nav>
    );
  }
}

Sidebar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(withStyles(styles)(Sidebar));
