import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete'
import 'react-google-places-autocomplete/dist/assets/index.css'

import {
  Button,
  CircularProgress,
  Typography,
  withStyles
} from '@material-ui/core'
import styles from './style'

import { getGeoLocation } from 'redux/actions/index'
import NewAddress from '../NewAddress'

class ShopAddress extends Component {
  componentDidMount () {
    navigator.geolocation.getCurrentPosition(
      this.getCurrentPosition,
      this.onGettingCurrentLocationError,
      {
        enableHighAccuracy: true,
        timeout: 20000,
        maximumAge: 10000
      }
    )
  }

    getCurrentPosition = position => {
      this.currentLocation = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      }
      this.props.handleFieldChange('latlng', this.currentLocation);
    }

    onGettingCurrentLocationError = () => {
      this.isLocationDisabled = true
    }

    onSelectCurrentLocation = () => {
      if (this.isLocationDisabled) {
        alert('Location permission is disabled')
      } else {
        this.props.handleFieldChange('latlng', this.currentLocation)
      }
    }

    onSelectLocation = location => {
      geocodeByPlaceId(location.place_id).then(item => {
        if (item && item.length && item[0].geometry) {
          this.currentLocation = item[0]
          const latLongData = item[0].geometry.location
          const latlng = {
            lat: latLongData.lat(),
            lng: latLongData.lng()
          }
          this.props.handleFieldChange('latlng', latlng)
        }
      })
    }

    getUpdatedCenter = value => {
      const location = `latlng=${value.lat()},${value.lng()}`
      this.props.dispatch(getGeoLocation(location))
    }

    submitAddressDetails = () => {
      const { location, submitShopAddressDetails, values } = this.props
      let lat = values.latlng.lat
      let lng = values.latlng.lng
      let locality
      if (location && location.data && location.data.geometry) {
        const { data } = location
        lat = data.geometry.location.lat
        lng = data.geometry.location.lng
        const selectedLocality = data.address_components.filter(item => item.types.find(type => type === 'locality'))
        if (selectedLocality && selectedLocality.length) {
          locality = selectedLocality[0].long_name || selectedLocality[0].short_name
        }
      } else if (this.currentLocation && this.currentLocation.geometry && this.currentLocation.geometry.location) {
        lat = this.currentLocation.geometry.location.lat()
        lng = this.currentLocation.geometry.location.lng()
        const selectedLocality = this.currentLocation.address_components.filter(item => item.types.find(type => type === 'locality'))
        if (selectedLocality && selectedLocality.length) {
          locality = selectedLocality[0].long_name || selectedLocality[0].short_name
        }
      }
      const address = {
        'address': values.level1,
        'locality': locality,
        'landmark': values.level2,
        'coordinates': {
          'lat': parseFloat(lat),
          'long': parseFloat(lng)
        }
      }
      submitShopAddressDetails({ address })
    }

    render () {
      const { classes, type, values, isValid, handleFieldChange, isLoading, submitError } = this.props
      const { latlng, level1, level2 } = values
      return (<form className={classes.form}>
        <Typography className={classes.title} variant='h2' > Shop Address </Typography>
        <Typography className={classes.subtitle} variant='body1' > Add shop address</Typography>
        <div className={classes.fields}>

          {!latlng || !latlng.lat ? (<GooglePlacesAutocomplete
            placeholder='Search Location'
            onSelect={this.onSelectLocation}
          />) : null}
          {!latlng || !latlng.lat ? (
            <Button className={classes.locationButton} color='primary' onClick={this.onSelectCurrentLocation}>Use Current Location</Button>
          ) : null}
          {latlng && latlng.lat ? <NewAddress level1={level1} level2={level2} latlng={latlng} handleFieldChange={handleFieldChange} getUpdatedCenter={this.getUpdatedCenter} /> : null}
        </div>
        {submitError && (
          <Typography className={classes.submitError} variant='body2' > {submitError} </Typography>
        )}
        {isLoading ? (
          <CircularProgress className={classes.progress} />
        ) : (
          <Button className={classes.signUpButton} color='primary' disabled={!isValid} onClick={this.submitAddressDetails} size='large' variant='contained'>SAVE </Button>
        )}
        <Typography className={classes.signIn} variant='body1' >
          { type !== 'signin' ? 'Have an account?' : 'New to ShopsApp' }{' '}
          <Link className={classes.signInUrl} to={type !== 'signin' ? '/sign-in' : '/sign-up'} >{type !== 'signin' ? 'Sign In' : 'Sign Up'}</Link>
        </Typography>
      </form>)
    }
}

ShopAddress.propTypes = {
  classes: PropTypes.object.isRequired
}

function mapStateToProps (state) {
  return {
    location: state.address.location
  }
}

export default withStyles(styles)(connect(mapStateToProps)(ShopAddress))
